import Sentence from "../../component/sentence";
import Layout from "../../component/layout";

const SentencePage = () => {
    return (
        <Layout>
            <Sentence />
        </Layout>
    )
}

export default SentencePage;