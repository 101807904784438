import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from 'swiper/modules';

import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {WordListDetailData} from "../../../api/mock/word";
import MemorizeHeader from "../common/memorize-header";
import * as S from "./styles";
import SwiperItem from "./swiper-item";

const WordDetail = () => {
    const [data, setData] = useState<any>(null);
    const {detailId} = useParams();

    useEffect(() => {
        setData(WordListDetailData.filter((item) => item.detailId === detailId)[0]);
    }, [detailId]);

    if (!data) return null;

    return (
        <S.Wrap>
            <MemorizeHeader/>

            <S.HeaderBx>
                <dl>
                    <dt>영어 단어</dt>
                    <dd>AI를 이용하여 문장을 공부해보세요.</dd>
                </dl>
            </S.HeaderBx>

            <S.Inner>
                <S.SwiperBx>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1.15}
                        centeredSlides={true}
                        pagination={{
                            type: 'fraction',
                        }}
                        modules={[Pagination, Navigation]}
                    >
                        {
                            data.list.map((item: any, idx: number) => (
                                <SwiperSlide key={`word-detail-${idx}`}>
                                    <SwiperItem word={item.word} meaning={item.meaning}/>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </S.SwiperBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default WordDetail;