import * as S from "./styles";
import {motion} from "framer-motion";
import {useEffect, useState} from "react";
import PopupSearch from "./popup-search";
import PopupWord from "./popup-word";
import {AnimatePresence} from "framer-motion";
import PopupLoading from "./popup-loading";
import {getChatGPT, getRandomWord} from "../../api/chatgptapi";
import SoundButton from "./sound-button";

function getFilter(value: string) {
    if (value === '' || !value) return '';
    return value.toLowerCase().replace(/[^a-zA-Z]/g, '')
}

const Sentence = () => {
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isPopup, setPopup] = useState<boolean>(false);
    const [isPopupWord, setPopupWord] = useState<boolean>(false);
    const [level, setLevel] = useState<string>('8');

    const [word, setWord] = useState<string>('시작합니다.');
    const [meaning, setMeaning] = useState<string>('원하는 단어를 클릭 하세요.');
    const [sentence, setSentence] = useState<string[]>([]);
    const [translation, setTranslation] = useState<string>('');
    const [soundText, setSoundText] = useState<string>('');

    const [rKey, setRandomKey] = useState<string>('');

    const [searchWord, setSearchWord] = useState<string>('');
    const [searchTranslation, setSearchTranslation] = useState<string>('');

    const sendMessage = async (value: string) => {
        if (isLoading) return;
        setLoadingMessage('문장을 만드는 중입니다.');
        setLoading(true);

        const ranNum = level + '세 나이'
        const question = `'${value}' 단어로 ${ranNum} 원어민 수준 영어 문장 만들어. JSON으로 출력: w(단어), n(뜻), s(문장), t(문장 뜻)`;

        console.log(question);

        let chat = await getChatGPT(question);
        chat = JSON.parse(chat.choices[0].message.content);

        setLoading(false);

        if (!chat) return;

        const {w, n, s, t} = chat as any;

        if (w === '' || n === '' || t === '' || s === '' || typeof s !== 'string') {
            alert('다시 시도해주세요.');
            return;
        }

        setWord(w);
        setMeaning(n);

        setSentence(s.toString().split(' '));
        setSoundText(s);
        setTranslation(t);

        setRandomKey('' + Math.random() * 1000);

        console.log(chat);
    };

    const onClickItem = async (word: string) => {
        if (isLoading) return;
        setLoadingMessage('단어 해석중입니다.');
        setLoading(true);

        const question = `'${word}' 뜻. 짧게.`;
        const chat = await getChatGPT(question)

        setLoading(false);

        setSearchWord(word);
        setSearchTranslation(chat.choices[0].message.content);

        setPopupWord(true);

        console.log('질문 :', question);
        console.log(chat.choices[0].message.content);
    };

    useEffect(() => {
        const rWord = getRandomWord();

        const timer = setTimeout(() => {
            sendMessage(rWord);
        }, 1500);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <>
            <S.Container>
                <S.HeaderContainer>
                    <S.HeaderBx>
                        <S.LevelBx>
                            <div>
                                <select name="" id="" onChange={(e) => setLevel(e.target.value)}>
                                    <option value="13">초급</option>
                                    <option value="17">중급</option>
                                    <option value="20">고급</option>
                                </select>
                                <img src="/icon/ic_arrow_down.svg" alt=""/>
                            </div>
                        </S.LevelBx>
                        <S.SearchBtn>
                            <button onClick={() => setPopup(true)}><img src="/icon/ic_search.svg" alt=""/></button>
                        </S.SearchBtn>
                    </S.HeaderBx>
                    <S.HelpBx>
                        생성된 문장의 <span>[단어]</span>를 선택하여,<br/>
                        새로운 문장을 생성하세요.
                    </S.HelpBx>
                </S.HeaderContainer>

                <S.WordBx>
                    <AnimatePresence>
                        {
                            isLoading && <S.LoadingBx>
                                <PopupLoading message={loadingMessage}/>
                            </S.LoadingBx>
                        }
                    </AnimatePresence>
                    <AnimatePresence mode={'wait'}>
                        <motion.dl
                            key={`test-${rKey}`}
                            initial={{opacity: 0, y: -15}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: -15}}
                            transition={{duration: 0.5, ease: 'easeOut'}}
                        >
                            <dt>{word}</dt>
                            <dd>{meaning}</dd>
                        </motion.dl>
                    </AnimatePresence>
                </S.WordBx>

                <S.SentenceBx>
                    <AnimatePresence mode={'wait'}>
                        <dl key={`sentance-${rKey}`}>
                            <dt>
                                <ul>
                                    {
                                        sentence.map((item, idx) => (
                                            <li
                                                key={`sentence-${idx}-${rKey}`}
                                                style={{
                                                    'color': getFilter(item) === getFilter(word) ? 'red' : 'black',
                                                }}
                                                onClick={() => getFilter(item) === getFilter(word) ? sendMessage(getFilter(item)) : onClickItem(item)}
                                            >
                                                <motion.span
                                                    initial={{y: 32}}
                                                    animate={{y: 0}}
                                                    exit={{y: 32}}
                                                    transition={{duration: 0.5, ease: 'easeOut'}}
                                                >
                                                    {item}
                                                </motion.span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </dt>
                            <dd>
                                <motion.span
                                    initial={{opacity: 0, y: 32}}
                                    animate={{opacity: 1, y: 0}}
                                    exit={{opacity: 0, y: 32}}
                                    transition={{duration: 0.5, ease: 'easeOut'}}
                                >
                                    {translation}
                                </motion.span>
                            </dd>
                            {
                                soundText && <SoundButton soundText={soundText}/>
                            }
                        </dl>

                    </AnimatePresence>
                </S.SentenceBx>

                {/*<ClipboardBx/>*/}
            </S.Container>
            <AnimatePresence>
                {
                    isPopup && <PopupSearch sendMessage={sendMessage} setPopup={setPopup}/>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    isPopupWord &&
                    <PopupWord
                        word={searchWord}
                        translation={searchTranslation}
                        sendMessage={sendMessage}
                        setPopup={setPopupWord}
                    />
                }
            </AnimatePresence>
        </>
    )
}

export default Sentence;