import styled from "styled-components";

export const Wrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
`

export const HeaderBx = styled.div`
    padding: 50px 0 30px;
    dl{
        display: flex;
        flex-direction: column;
        align-items: center;
        dt{
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        dd{
            font-size: 14px;
            font-weight: 500;
            text-align: center;
        }
    }
`

export const Inner = styled.div`
    overflow: auto;
    height: 100%;
    padding: 0 20px 100px;
    box-sizing: border-box;
`

export const WordList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    h3{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    ul{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
        li{
            a{
                font-size: 13px;
                font-weight: 600;
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                border: 1px solid #cecece;
                border-radius: 10px;
                overflow: hidden;
                color: #000000;
            }
        }
    }
`