import styled from "styled-components";

export const Wrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
`

export const HeaderBx = styled.div`
    padding: 50px 0 30px;
    dl{
        display: flex;
        flex-direction: column;
        align-items: center;
        dt{
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        dd{
            font-size: 14px;
            font-weight: 500;
        }
    }
`

export const Inner = styled.div`
    overflow: auto;
    height: 100%;
    padding: 0 0 100px;
    box-sizing: border-box;
`

export const SwiperBx = styled.div`
    height: calc(100% - 100px);
    
    .swiper{
        height: 100%;
    }
    
    .swiper-pagination{
        span{
            font-size: 14px;
            font-weight: 700;
        }
    }

    .swiper-button-next, .swiper-button-prev {
        background-color: rgba(0, 0, 0, 0.1);
        padding: 5px;
        color: white;

        &:hover {
            &::after {
                font-size: 25px;
            }
        }

        &::after {
            transition: font-size 0.125s ease-out;
            font-size: 30px;
        }
    }
`

