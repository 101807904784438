import styled from "styled-components";
import {motion} from "framer-motion";
export const PopupBx = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`

export const PopupInner = styled(motion.div)`
    width: 100%;
    position: relative;
    z-index: 10;
    background-color: #ffffff;
    padding: 20px 15px;
    border-radius: 10px;
    max-width: 300px;
    box-sizing: border-box;
`

export const PopupDimmed = styled(motion.div)`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba( 0, 0, 0, 0.5 );
`

export const CloseBtn = styled.div`
    position: absolute;
    right: 5px;
    top: 5px;

    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        font-size: 0;
        margin: 0;
        padding: 0;

        img {
            width: 20px;
            height: 20px;
        }
    }
`

export const Title = styled.div`
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
`

export const SearchBx = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    input {
        width: 100%;
        padding: 5px 10px;
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: 600;
        border: none;
        border-bottom: 1px solid #999999;
        text-align: center;
        box-sizing: border-box;

        &::placeholder {
            font-size: 14px;
            font-weight: 600;
        }
    }

    button {
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid #000000;
        padding: 13px 0;
        border-radius: 10px;
        background-color: #4545ee;
        color: #ffffff;
    }
`