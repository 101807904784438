import styled from "styled-components";

export const Wrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
`

export const HeaderBx = styled.div`
    padding: 50px 0 30px;
    dl{
        display: flex;
        flex-direction: column;
        align-items: center;
        dt{
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        dd{
            font-size: 14px;
            font-weight: 500;
        }
    }
`

export const Inner = styled.div`
    overflow: auto;
    height: 100%;
    padding: 0 20px 100px;
    box-sizing: border-box;
`

export const ListBx = styled.div`
    ul {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-bottom: 110px;

        li {
            a {
                display: block;
                font-size: 14px;
                color: #000000;
                font-weight: 600;
                padding: 17px 20px;
                box-sizing: border-box;
                border: 1px solid #cecece;
                border-radius: 10px;
            }
        }
    }
`