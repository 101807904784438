import styled, {keyframes} from "styled-components";
import {motion} from "framer-motion";
export const PopupBx = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`

export const PopupInner = styled(motion.div)`
    position: relative;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
`

export const PopupContent = styled(motion.div)`
    position: relative;
    width: 100%;
    background-color: #ffffff;
    padding: 40px 15px 20px;
    border-radius: 20px;
    max-width: 400px;
    box-sizing: border-box;
    box-shadow: 1px 1px 50px rgba( 0, 0, 0, 0.2 );
`

export const PopupDimmed = styled(motion.div)`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba( 0, 0, 0, 0.1 );
`

export const CloseBtn = styled.div`
    position: absolute;
    right: 5px;
    top: 5px;

    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        font-size: 0;
        margin: 0;
        padding: 0;

        img {
            width: 20px;
            height: 20px;
        }
    }
`

export const Title = styled.div`
    font-size: 28px;
    font-weight: 700;
    color: #000000;
    text-align: center;
    margin-bottom: 15px;
`

export const SearchBx = styled.dl`
    display: flex;
    align-items: center;
    flex-direction: column;

    dt {
        width: 100%;
        padding: 5px 10px;
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 600;
        border: none;
        text-align: center;
        box-sizing: border-box;
        word-break: keep-all;
        line-height: 17px;

        &::placeholder {
            font-size: 14px;
            font-weight: 600;
        }
    }
    
    dd{
        display: flex;
        gap: 5px;
        width: 100%;
        button {
            &:nth-child(1){
                background-color: gray;
            }
            
            width: 100%;
            font-size: 14px;
            font-weight: 700;
            padding: 13px 0;
            border-radius: 10px;
            background-color: #4545ee;
            color: #ffffff;
        }
    }
`

export const Pulse = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: .3;
    }
`

export const MessageBx = styled(motion.div)`
    pointer-events: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -60px;
    font-size: 14px;
    color: #000000;
    
    span{
        animation: ${Pulse} 0.75s ease 0s infinite alternate;       
    }
`