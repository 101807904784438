import {AnimatePresence, motion} from "framer-motion";
import * as S from "./styles";
import {useState} from "react";
import {getChatGPT} from "../../../../api/chatgptapi";
import PopupLoading from "../../../sentence/popup-loading";
import SoundButton from "../../../sentence/sound-button";

function getFilter(value: string) {
    if (value === '' || !value) return '';
    return value.toLowerCase().replace(/[^a-zA-Z]/g, '')
}

function isSame(a: string, b: string) {
    const v1 = getFilter(a);
    const v2 = getFilter(b);

    return v1.indexOf(v2) > -1;
}

interface Props {
    word: string;
    meaning: string;
}

const SwiperItem = ({word, meaning}: Props) => {
    const [rKey, setRandomKey] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const [sentence, setSentence] = useState<string[]>([]);
    const [translation, setTranslation] = useState<string>('');

    const [soundText, setSoundText] = useState<string>('');

    const onClick = async (value: string) => {
        if (isLoading) return;
        setLoading(true);

        let question = `'${value}' 단어로 영어 문장 만들어.`;
        if (sentence) {
            question += ` [${soundText}]이 문장은 빼.`
        }

        question += ' JSON으로 출력: s(문장), t(문장 뜻)'

        let chat = await getChatGPT(question);
        chat = JSON.parse(chat.choices[0].message.content);

        setLoading(false);

        if (!chat) return;

        const {s, t} = chat as any;

        if (t === '' || s === '' || typeof s !== 'string') {
            alert('다시 시도해주세요.');
            return;
        }

        setTranslation(t);
        setSentence(s.toString().split(' '));
        setRandomKey('' + Math.random() * 1000);
        setSoundText(s.toString());
    }

    return (
        <S.SwiperContent>
            <S.WordBx>
                <dl>
                    <dt>{word}</dt>
                    <dd>{meaning}</dd>
                </dl>
            </S.WordBx>

            <S.Sentence>
                <AnimatePresence mode={'wait'}>
                    <dl key={`sentance-${rKey}`}>
                        <dt>
                            <ul>
                                {
                                    sentence.map((item, idx) => (
                                        <li
                                            key={`sentence-${idx}-${rKey}`}
                                            style={{
                                                'color': isSame(item, word) ? 'red' : 'black',
                                            }}
                                        >
                                            <motion.span
                                                initial={{y: 32}}
                                                animate={{y: 0}}
                                                exit={{y: 32}}
                                                transition={{
                                                    duration: 0.5,
                                                    ease: 'easeOut'
                                                }}
                                            >
                                                {item}
                                            </motion.span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </dt>
                        <dd>
                            <motion.span
                                initial={{opacity: 0, y: 32}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: 32}}
                                transition={{duration: 0.5, ease: 'easeOut'}}
                            >
                                {translation}
                            </motion.span>
                        </dd>
                        <dd>
                            {
                                soundText && <SoundButton soundText={soundText}/>
                            }
                        </dd>
                    </dl>

                </AnimatePresence>
            </S.Sentence>

            <S.SentenceBtn $isLoading={isLoading}>
                <AnimatePresence>
                    <S.LoadingBx>
                        {
                            isLoading && <PopupLoading message={'문장을 만드는 중입니다.'}></PopupLoading>
                        }
                    </S.LoadingBx>
                </AnimatePresence>
                <button onClick={() => onClick(word)}>새로운 문장 만들기</button>
            </S.SentenceBtn>
        </S.SwiperContent>
    )
}

export default SwiperItem;