import * as S from "./styles";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

const MemorizeHeader = () => {
    const naviagte = useNavigate();
    const onClick = useCallback(() => {
        naviagte(-1);
    }, []);

    return (
        <S.Wrap>
            <S.Inner>
                <button onClick={onClick}>
                    <img src="/icon/ic_arrow_left.svg" alt=""/>
                </button>
            </S.Inner>
        </S.Wrap>
    )
}

export default MemorizeHeader;