import styled from "styled-components";

export const Wrap = styled.div`
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #ffffff;
`

export const Inner = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding: 10px 10px;
    box-sizing: border-box;
`