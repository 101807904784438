import {API, TTS} from "../libs/key";

export const getChatGPT = async (question:string) => {
    try {
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${API}`,
            },
            body: JSON.stringify({
                model: 'gpt-3.5-turbo',
                messages: [
                    {role: 'system', content: '모든 응답을 한국어로 작성하세요.'},
                    {
                        role: 'user',
                        content: question
                    }
                ]
            }),
        });

        return await response.json();
    } catch (error) {
        console.error( error );
        alert('다시 시도해주세요.');

        return null;
    }
}

export const getChatGPSpeech = async (text: string ) => {
    const apiUrl = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${TTS}`;

    return await fetch(apiUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            input: { text },
            voice: { languageCode: "en-US", name: "en-US-News-K" },
            audioConfig: { audioEncoding: "MP3" },
        }),
    });
}

export const getRandomWord = () => {
    const words = [
        "apple", "banana", "grape", "orange", "kiwi", "pineapple", "mango", "cherry", "pear", "peach",
        "dog", "cat", "elephant", "lion", "tiger", "rabbit", "kangaroo", "horse", "cow", "sheep",
        "car", "bicycle", "plane", "train", "boat", "bus", "motorcycle", "scooter", "helicopter", "submarine",
        "book", "pen", "pencil", "notebook", "paper", "marker", "eraser", "scissors", "ruler", "glue",
        "mountain", "river", "lake", "forest", "ocean", "desert", "sky", "cloud", "rain", "snow",
        "computer", "phone", "tablet", "headphones", "camera", "television", "clock", "microwave", "fridge"
    ];

    return words[Math.floor(Math.random() * words.length)];
}