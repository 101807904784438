import * as S from "./styles";
import ClipboardBx from "../common/clipboard-bx";

const Info = () => {
    return (
        <S.Wrap>
            <S.Inner>
                <div>이름 : 영어꼬리</div>
                <div>버전 : v0.01</div>

                <ClipboardBx/>
                <a href="mailto:kineage18@gmail.com">
                    문의 하기<br/>
                    <span>(많은 피드백 부탁드립니다.)</span>
                </a>
            </S.Inner>
        </S.Wrap>
    )
}

export default Info;