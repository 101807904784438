import Word from "../../../component/memorize/word";
import Layout from "../../../component/layout";
const WordPage = () => {
    return(
        <Layout>
            <Word></Word>
        </Layout>
    )
}

export default WordPage;