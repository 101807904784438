import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 270px;
`

export const WordBx = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    dl {
        dt {
            text-align: center;
            font-size: 34px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        dd {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
        }
    }
`

export const LoadingBx = styled.div`
    position: absolute;
    width: 300px;
    left: 50%;
    top: -50px;
    transform: translateX(-50%);
`

export const SentenceBx = styled.div`
    position: relative;

    dl {
        display: flex;
        flex-direction: column;

        dt {
            margin-bottom: 30px;

            ul {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 5px;

                li {
                    position: relative;
                    cursor: pointer;
                    font-size: 26px;
                    font-weight: 600;
                    overflow: hidden;
                    height: 30px;
                    
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    &:hover {
                        color: blue;
                    }

                    span {
                        display: block;
                        position: relative;
                        
                        &:after{
                            opacity: 0;
                            content: '↗';
                            position: absolute;
                            right: 0;
                            top: 0;
                            display: inline-block;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        dd {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            word-break: keep-all;
            overflow: hidden;

            span {
                display: inline-block;
            }
        }
    }
`

export const HeaderContainer = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
`

export const HelpBx = styled.div`
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;

    span {
        font-size: 14px;
        font-weight: 700;
    }
`

export const HeaderBx = styled.div`
    padding: 20px 20px 15px;
    box-sizing: border-box;
    width: 100%;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const LevelBx = styled.div`
    box-sizing: border-box;
    
    > div{
        border-bottom: 1px solid black;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        select{
            padding: 2px;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: 700;
            border: none;
            background-color: transparent;
            color: black;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
    }
`

export const SearchBtn = styled.div`
    
`