import React from 'react';
import {Route, Routes} from "react-router-dom";

import HomePage from "./pages/HomePage";
import SentencePage from "./pages/SentencePage";
import WordPage from "./pages/memorize/WordPage"
import WordListPage from "./pages/memorize/WordListPage";
import WordDetailPage from "./pages/memorize/WordDetailPage";
import InfoPage from "./pages/InfoPage";

import {AnimatePresence} from "framer-motion";
import {useLocation} from "react-router-dom";


import BottomMenu from "./component/bottom-menu";

function App() {
    const location = useLocation();

    return (
        <div className="App">
            <AnimatePresence mode={"wait"}>
                <Routes location={location} key={location.key}>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/sentence" element={<SentencePage/>}/>
                    <Route path="/memorize">
                        <Route path="word" element={<WordPage/>}/>
                        <Route path="word-list/:groupId" element={<WordListPage/>}/>
                        <Route path="word-detail/:detailId" element={<WordDetailPage/>}/>
                    </Route>
                    <Route path="/info" element={<InfoPage />}></Route>
                </Routes>
            </AnimatePresence>
            <BottomMenu />
        </div>
    );
}

export default App;
