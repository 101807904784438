import * as S from "./styles";
import {useCallback, useState} from "react";

interface Props {
    sendMessage: (value: string) => void;
    setPopup: (isPopup: boolean) => void;
}

const PopupSearch = ({sendMessage, setPopup}: Props) => {
    const [search, setSearch] = useState<string>('');
    const onClickSearch = useCallback(() => {
        if (!search || search === '') {
            alert('단어를 입력해주세요');
            return
        }

        sendMessage(search);
        setPopup(false);
    }, [search]);

    return (
        <S.PopupBx>
            <S.PopupInner
                initial={{y: 20, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                exit={{y: 20, opacity: 0}}
                transition={{
                    duration: 0.2,
                    ease: 'easeOut'
                }}
            >
                <S.CloseBtn>
                    <button onClick={() => setPopup(false)}>
                        <img src="/icon/ic_btn_close.svg" alt=""/>
                    </button>
                </S.CloseBtn>
                <S.Title>검색</S.Title>
                <S.SearchBx>
                    <input
                        type="text"
                        placeholder={'단어 입력해 주세요.'}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button onClick={onClickSearch}>문장 만들기</button>
                </S.SearchBx>
            </S.PopupInner>

            <S.PopupDimmed
                onClick={() => setPopup(false)}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{
                    duration: 0.2,
                    ease: 'easeOut'
                }}
            ></S.PopupDimmed>
        </S.PopupBx>
    )
}

export default PopupSearch;
