import {ReactNode} from "react";
import * as S from "./styles";

interface Props {
    children: ReactNode
}

const Layout = ({children}: Props) => {
    return (
        <S.Wrap>
            <S.Inner>
                <S.Content
                    initial={{ opacity: 0}}
                    animate={{ opacity: 1}}
                    exit={{ opacity: 0}}
                    transition={{duration: .5, ease: 'easeOut'}}
                >
                    {children}
                </S.Content>

                {/*<S.Bg*/}
                {/*    initial={{scaleY: 1}}*/}
                {/*    animate={{scaleY: 0, transformOrigin: 'center top'}}*/}
                {/*    exit={{scaleY: 1, transformOrigin: 'center bottom'}}*/}
                {/*    transition={{duration: 1, ease: 'easeOut'}}*/}
                {/*/>*/}
            </S.Inner>
        </S.Wrap>
    )
}

export default Layout;