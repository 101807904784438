import styled from "styled-components";

const Wrap = styled.div`
    padding: 0 20px;
    box-sizing: border-box;

    button, a {
        width: 80px;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid #000000;
        padding: 10px 0;
        border-radius: 10px;
        background-color: #4545ee;
        color: #ffffff;
    }
`
const ClipboardBx = () => {
    const onClickCopyClipBoard = async () => {
        try {
            await navigator.clipboard.writeText('https://english-tail.com');
            alert("주소 복사 되었습니다.");
        } catch (e) {
            alert("다시 시도해주세요");
        }
    }

    return (
        <Wrap>
            <button onClick={onClickCopyClipBoard}>링크 공유</button>
        </Wrap>
    )
}

export default ClipboardBx;