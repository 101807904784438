import styled, {keyframes} from "styled-components";
import {motion} from "framer-motion";

export const Wrap = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: 15px;
`

export const Message = styled.div`
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: #999999;
`

export const Pulse = keyframes`
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: .25;
        transform: scale(.75);
    }
`
export const SpinnerBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    > div {
        width: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            //background-color: #3ff9dc;
            background-color: rgba(0, 0, 0, 0.5);

            &:nth-child(1) {
                animation: ${Pulse} .4s ease 0s infinite alternate;
            }

            &:nth-child(2) {
                animation: ${Pulse} .4s ease .2s infinite alternate;
            }

            &:nth-child(3) {
                animation: ${Pulse} .4s ease .4s infinite alternate;
            }
        }
    }
`