import * as S from "./styles";
import {WordGroup} from "../../../api/mock/word";
import {Link} from "react-router-dom";

const Word = () => {
    return (
        <S.Wrap>
            <S.Inner>
                <S.HeaderBx>
                    <dl>
                        <dt>영어 단어</dt>
                        <dd>
                            영어 단어를 공부해보세요.
                            <br />
                            (업데이트 중입니다.)
                        </dd>
                    </dl>
                </S.HeaderBx>
                <S.WordList>
                    {
                        WordGroup.map((group, groupId) => (
                            <div key={`group-${groupId}`}>
                                <h3>{group.title}</h3>
                                <ul>
                                    {
                                        group.list.map((item, itemId) => (
                                            <li key={`group-list-${groupId}-${itemId}`}>
                                                <Link to={`/memorize/word-list/${item.groupdId}`}>
                                                    {item.title}
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        ))
                    }
                </S.WordList>
            </S.Inner>
        </S.Wrap>
    )
}

export default Word;