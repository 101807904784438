import styled from "styled-components";

export const Wrap = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 10px;
    box-sizing: border-box;
    background-color: #ffffff;
`


export const Inner = styled.div`
    max-width: 600px;
    margin: 0 auto;
`

export const Container = styled.div`
    padding: 10px 10px 0;
    border-top: 1px solid #cecece;
    ul{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 5px;
        li{
        }
    }
`

export const MenuBx = styled.li<{ $isIn: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    button, a{
        width: 100%;
        padding: 10px 0;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid #cecece;
        border-radius: 10px;
        color: ${ p => p.$isIn ? '#ffffff' : '#000000' };
        background-color: ${ p => p.$isIn ? '#000000' : '#ffffff' };
    }
    
`