import Layout from "../../../component/layout";
import WordList from "../../../component/memorize/word-list";

const WordListPage = () => {
    return(
        <Layout>
            <WordList></WordList>
        </Layout>
    )
}

export default WordListPage;