import Layout from "../../../component/layout";
import WordDetail from "../../../component/memorize/word-detail";

const WordDetailPage = () => {
    return (
        <Layout>
            <WordDetail></WordDetail>
        </Layout>
    )
}

export default WordDetailPage;