import Info from "../../component/info";
import Layout from "../../component/layout";
const InfoPage =() => {
    return(
        <Layout>
            <Info></Info>
        </Layout>
    )
}

export default InfoPage;