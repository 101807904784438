import * as S from "./styles";

interface Props{
    message: string;
}

const PopupLoading = ({ message }: Props ) => {
    return(
        <S.Wrap
            initial={{ opacity: 0 }}
            animate={{ opacity: 1}}
            exit={{ opacity: 0 }}
        >
            <S.SpinnerBox>
                <div className="pulse-container">
                    <div className="pulse-bubble pulse-bubble-1"></div>
                    <div className="pulse-bubble pulse-bubble-2"></div>
                    <div className="pulse-bubble pulse-bubble-3"></div>
                </div>
            </S.SpinnerBox>
            <S.Message>{message}</S.Message>
        </S.Wrap>
    )
}

export default PopupLoading;