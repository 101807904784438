export const WordGroup = [
    {
        wordId: "1",
        title: '기초',
        list: [
            {
                title: '기초 영단어 1',
                groupdId: "101",
            },
            {
                title: '기초 영단어 2',
                groupdId: "102",
            },
        ]
    },
    {
        wordId: "2",
        title: '중급',
        list: []
    },
]

export const WordListData = [
    {
        groupId: "101",
        list: [
            {
                title: '기초 영단어 1-1',
                detailId: "1001",
            },
            {
                title: '기초 영단어 1-2',
                detailId: "1002",
            },
            {
                title: '기초 영단어 1-3',
                detailId: "1003",
            },
            {
                title: '기초 영단어 1-4',
                detailId: "1004",
            },
            {
                title: '기초 영단어 1-5',
                detailId: "1005",
            },
            {
                title: '기초 영단어 1-6',
                detailId: "1006",
            },
            {
                title: '기초 영단어 1-7',
                detailId: "1007",
            },
            {
                title: '기초 영단어 1-8',
                detailId: "1008",
            },
            {
                title: '기초 영단어 1-9',
                detailId: "1009",
            },
            {
                title: '기초 영단어 1-10',
                detailId: "1010",
            },
            {
                title: '기초 영단어 1-11',
                detailId: "1011",
            },
            {
                title: '기초 영단어 1-12',
                detailId: "1012",
            },
            {
                title: '기초 영단어 1-13',
                detailId: "1013",
            },
            {
                title: '기초 영단어 1-14',
                detailId: "1014",
            },
            {
                title: '기초 영단어 1-15',
                detailId: "1015",
            },
        ]
    },
    {
        groupId: "102",
        list: [
            {
                title: '기초 영단어 2-1',
                detailId: "1021",
            },
            {
                title: '기초 영단어 2-2',
                detailId: "1022",
            },
            {
                title: '기초 영단어 2-3',
                detailId: "1023",
            },
            {
                title: '기초 영단어 2-4',
                detailId: "1024",
            },
            {
                title: '기초 영단어 2-5',
                detailId: "1025",
            },
            {
                title: '기초 영단어 2-6',
                detailId: "1026",
            },
            {
                title: '기초 영단어 2-7',
                detailId: "1027",
            },
            {
                title: '기초 영단어 2-8',
                detailId: "1028",
            },
            {
                title: '기초 영단어 2-9',
                detailId: "1029",
            },
            {
                title: '기초 영단어 2-10',
                detailId: "1030",
            },
            {
                title: '기초 영단어 2-11',
                detailId: "1031",
            },
            {
                title: '기초 영단어 2-12',
                detailId: "1032",
            },
            {
                title: '기초 영단어 2-13',
                detailId: "1033",
            },
            {
                title: '기초 영단어 2-14',
                detailId: "1034",
            },
            {
                title: '기초 영단어 2-15',
                detailId: "1035",
            },
        ]
    },
]

export const WordListDetailData = [
    {
        detailId: "1001",
        list: [
            {
                "word": "hello",
                "meaning": "안녕, 안녕하세요"
            },
            {
                "word": "good-bye",
                "meaning": "안녕히 가세요"
            },
            {
                "word": "here",
                "meaning": "여기에"
            },
            {
                "word": "mom",
                "meaning": "엄마"
            },
            {
                "word": "see",
                "meaning": "보다"
            },
            {
                "word": "there",
                "meaning": "그곳에, 거기에"
            },
            {
                "word": "ok",
                "meaning": "좋아, 그래"
            },
            {
                "word": "drink",
                "meaning": "마시다"
            },
            {
                "word": "eat",
                "meaning": "먹다"
            },
            {
                "word": "hungry",
                "meaning": "배고픈"
            },
        ],
    },
    {
        detailId: "1002",
        list: [
            {
                "word": "say",
                "meaning": "말하다"
            },
            {
                "word": "thirsty",
                "meaning": "목이마른; 갈망하는"
            },
            {
                "word": "push",
                "meaning": "밀다; 추진하다; 누르다"
            },
            {
                "word": "stand",
                "meaning": "서다, 서있다; 견뎌내다"
            },
            {
                "word": "speak",
                "meaning": "이야기하다, 말하다"
            },
            {
                "word": "sit",
                "meaning": "앉다"
            },
            {
                "word": "talk",
                "meaning": "말하다, 이야기하다, 대화하다"
            },
            {
                "word": "dad",
                "meaning": "아빠"
            },
            {
                "word": "like",
                "meaning": "좋아하다"
            },
            {
                "word": "walk",
                "meaning": "걷다, 산책하다;"
            }
        ],
    },
    {
        detailId: "1003",
        list: [
            {
                "word": "word",
                "meaning": "단어, 낱말, 말; 약속"
            },
            {
                "word": "wish",
                "meaning": "바라다, 희망하다"
            },
            {
                "word": "tell",
                "meaning": "말하다, 이야기하다"
            },
            {
                "word": "turn",
                "meaning": "돌다, 회전하다"
            },
            {
                "word": "touch",
                "meaning": "만지다; 감동시키다;"
            },
            {
                "word": "friend",
                "meaning": "친구"
            },
            {
                "word": "smile",
                "meaning": "미소 짓다;"
            },
            {
                "word": "find",
                "meaning": "찾다, 발견하다"
            },
            {
                "word": "child",
                "meaning": "아이, 어린이"
            },
            {
                "word": "wait",
                "meaning": "기다리다"
            }
        ],
    },
    {
        detailId: "1004",
        list: [
            {
                "word": "throw",
                "meaning": "던지다, 팽개치다; 발사하다;"
            },
            {
                "word": "wear",
                "meaning": "입고 있다; 낡게 하다"
            },
            {
                "word": "watch",
                "meaning": "보다, 지켜보다"
            },
            {
                "word": "stay",
                "meaning": "머무르다, 체류하다"
            },
            {
                "word": "die",
                "meaning": "죽다"
            },
            {
                "word": "look",
                "meaning": "보다, 보이다; 찾다; 주목하다"
            },
            {
                "word": "night",
                "meaning": "밤, 야간"
            },
            {
                "word": "use",
                "meaning": "쓰다, 사용하다"
            },
            {
                "word": "stop",
                "meaning": "멈추다, 중단하다"
            },
            {
                "word": "feel",
                "meaning": "느끼다"
            },
        ],
    },
    {
        detailId: "1005",
        list: [
            {
                "word": "sad",
                "meaning": "슬픈"
            },
            {
                "word": "wet",
                "meaning": "젖은"
            },
            {
                "word": "sleep",
                "meaning": "잠, 수면; 자다"
            },
            {
                "word": "hi",
                "meaning": "안녕"
            },
            {
                "word": "please",
                "meaning": "제발, 부디"
            },
            {
                "word": "sorry",
                "meaning": "미안한, 안된, 유감스러운"
            },
            {
                "word": "snow",
                "meaning": "눈"
            },
            {
                "word": "thank",
                "meaning": "감사하다, 고마워하다"
            },
            {
                "word": "rain",
                "meaning": "비"
            },
            {
                "word": "hey",
                "meaning": "어이, 이봐"
            }
        ],
    },
    {
        detailId: "1006",
        list: [
            {
                "word": "mignight",
                "meaning": "자정, 한밤중"
            },
            {
                "word": "run",
                "meaning": "달리다; 운영하다; 작동하다; 개최하다;"
            },
            {
                "word": "sometimes",
                "meaning": "때때로, 가끔"
            },
            {
                "word": "evening",
                "meaning": "저녁"
            },
            {
                "word": "today",
                "meaning": "오늘, 오늘날에, 현재"
            },
            {
                "word": "shop",
                "meaning": "가게, 상점"
            },
            {
                "word": "morning",
                "meaning": "아침, 오전"
            },
            {
                "word": "afternoon",
                "meaning": "오후"
            },
            {
                "word": "tonight",
                "meaning": "\b오늘 밤에; 오늘 밤"
            },
            {
                "word": "tomorrow",
                "meaning": "내일"
            }
        ],
    },
    {
        detailId: "1007",
        list: [
            {
                "word": "gray",
                "meaning": "회색"
            },
            {
                "word": "brown",
                "meaning": "갈색"
            },
            {
                "word": "jump",
                "meaning": "뛰다, 점프하다"
            },
            {
                "word": "blue",
                "meaning": "파란색"
            },
            {
                "word": "clothes",
                "meaning": "옷, 의복"
            },
            {
                "word": "purple",
                "meaning": "보라색, 자주색, 퍼플"
            },
            {
                "word": "orange",
                "meaning": "오렌지"
            },
            {
                "word": "know",
                "meaning": "알다"
            },
            {
                "word": "pink",
                "meaning": "핑크색, 분홍색"
            },
            {
                "word": "green",
                "meaning": null
            }
        ],
    },
    {
        detailId: "1008",
        list: [
            {
                "word": "later",
                "meaning": "나중에, 후에"
            },
            {
                "word": "good",
                "meaning": "좋은"
            },
            {
                "word": "last",
                "meaning": "지난, 가장 최근의; 마지막의"
            },
            {
                "word": "now",
                "meaning": "지금, 이제"
            },
            {
                "word": "cook",
                "meaning": "요리하다"
            },
            {
                "word": "lunch",
                "meaning": "점심"
            },
            {
                "word": "next",
                "meaning": "다음의"
            },
            {
                "word": "breakfast",
                "meaning": "아침식사"
            },
            {
                "word": "yesterday",
                "meaning": "어제"
            },
            {
                "word": "dinner",
                "meaning": "저녁식사, 저녁"
            }
        ],
    },
    {
        detailId: "1009",
        list: [
            {
                "word": "fine",
                "meaning": "좋은, 훌륭한"
            },
            {
                "word": "meet",
                "meaning": "만나다; 충족시키다"
            },
            {
                "word": "buy",
                "meaning": "사다, 구입하다"
            },
            {
                "word": "red",
                "meaning": "빨간색"
            },
            {
                "word": "moon",
                "meaning": "달"
            },
            {
                "word": "white",
                "meaning": "흰색"
            },
            {
                "word": "color",
                "meaning": "색"
            },
            {
                "word": "week",
                "meaning": "주, 일주일"
            },
            {
                "word": "in",
                "meaning": "~안에, ~에서, ~으로, ~후에"
            },
            {
                "word": "black",
                "meaning": "검은색"
            }
        ],
    },
    {
        detailId: "1010",
        list: [
            {
                "word": "worry",
                "meaning": "걱정하다; 괴롭히다"
            },
            {
                "word": "read",
                "meaning": "읽다, 독서하다"
            },
            {
                "word": "study",
                "meaning": "공부, 연구"
            },
            {
                "word": "end",
                "meaning": "끝, 마지막"
            },
            {
                "word": "wash",
                "meaning": "씻다, 세탁하다, 세척하다"
            },
            {
                "word": "call",
                "meaning": "부르다, 전화하다"
            },
            {
                "word": "name",
                "meaning": "이름"
            },
            {
                "word": "swim",
                "meaning": "수영하다, 헤엄치다"
            },
            {
                "word": "work",
                "meaning": "일하다, 작업하다"
            },
            {
                "word": "write",
                "meaning": "쓰다, 작성하다"
            }
        ],
    },
    {
        detailId: "1011",
        list: [
            {
                "word": "share",
                "meaning": "함께 쓰다, 공유하다, 나누다"
            },
            {
                "word": "leave",
                "meaning": "떠나다; 남기다; 맡기다; 출발하다"
            },
            {
                "word": "start",
                "meaning": "시작하다, 출발하다; 시작"
            },
            {
                "word": "where",
                "meaning": "어디에, 어디로"
            },
            {
                "word": "show",
                "meaning": "보여주다"
            },
            {
                "word": "when",
                "meaning": "언제, ~할 때"
            },
            {
                "word": "what",
                "meaning": "무엇; 어떤 것; 정말, 얼마나"
            },
            {
                "word": "who",
                "meaning": "누구"
            },
            {
                "word": "open",
                "meaning": "열다"
            },
            {
                "word": "hear",
                "meaning": "듣다, 들리다"
            },
        ],
    },
    {
        detailId: "1012",
        list: [
            {
                "word": "long",
                "meaning": "긴, 오래; 오랫동안"
            },
            {
                "word": "cool",
                "meaning": "시원한, 서늘한; 멋진"
            },
            {
                "word": "big",
                "meaning": "큰, 크게"
            },
            {
                "word": "top",
                "meaning": "맨 위, 꼭대기, 정상; 팽이;"
            },
            {
                "word": "hot",
                "meaning": "뜨거운; 매운"
            },
            {
                "word": "warm",
                "meaning": "따뜻한"
            },
            {
                "word": "large",
                "meaning": "큰"
            },
            {
                "word": "few",
                "meaning": "거의 없는, 소수의, 몇몇의"
            },
            {
                "word": "late",
                "meaning": "늦은"
            },
            {
                "word": "again",
                "meaning": "다시, 다시 한번"
            },
        ],
    },
    {
        detailId: "1013",
        list: [
            {
                "word": "which",
                "meaning": "어느, 어떤; 어떤 사람, 어느 것"
            },
            {
                "word": "why",
                "meaning": "왜"
            },
            {
                "word": "many",
                "meaning": "많은"
            },
            {
                "word": "some",
                "meaning": "조금, 약간의"
            },
            {
                "word": "little",
                "meaning": "작은; 어린;"
            },
            {
                "word": "great",
                "meaning": "큰, 거대한; 좋은; 위대한"
            },
            {
                "word": "how",
                "meaning": "어떻게; 얼마나"
            },
            {
                "word": "book",
                "meaning": "예약하다; 책, 도서"
            },
            {
                "word": "nice",
                "meaning": "좋은, 즐거운; 친절한"
            },
            {
                "word": "much",
                "meaning": "많은; 매우, 너무"
            }
        ],
    },
    {
        detailId: "1014",
        list: [
            {
                "word": "exit",
                "meaning": "출구; 나가다"
            },
            {
                "word": "care",
                "meaning": "돌봄, 보살핌"
            },
            {
                "word": "drive",
                "meaning": "운전하다"
            },
            {
                "word": "cry",
                "meaning": "울다, 외치다, 울부짖다"
            },
            {
                "word": "brush",
                "meaning": "솔질하다, 닦다"
            },
            {
                "word": "fly",
                "meaning": "날다, 비행하다"
            },
            {
                "word": "come",
                "meaning": "(~쪽으로) 오다, 움직이다"
            },
            {
                "word": "glad",
                "meaning": "기쁜, 반가운"
            },
            {
                "word": "cut",
                "meaning": "삭감하다, 일하하다; 자르다"
            },
            {
                "word": "dance",
                "meaning": "춤추다; 춤, 무용, 댄스"
            },
        ]
    },
    {
        detailId: "1015",
        list: [
            {
                "word": "different",
                "meaning": "다른, 차이가 나는"
            },
            {
                "word": "together",
                "meaning": "함께, 같이"
            },
            {
                "word": "small",
                "meaning": "작은, 소규모의"
            },
            {
                "word": "cold",
                "meaning": "추운, 차가운; 추위; 감기"
            },
            {
                "word": "same",
                "meaning": "같은, 동일한"
            },
            {
                "word": "old",
                "meaning": "나이든; 오래된"
            },
            {
                "word": "young",
                "meaning": "젋은, 어린;"
            },
            {
                "word": "tall",
                "meaning": "키가 큰, 높은"
            },
            {
                "word": "new",
                "meaning": "새로운"
            },
            {
                "word": "back",
                "meaning": "뒤쪽; 등; 뒤의"
            }
        ]
    },
    {
        detailId: "1021",
        list: [
            {
                "word": "sport",
                "meaning": "스포츠, 운동"
            },
            {
                "word": "exercise",
                "meaning": "운동"
            },
            {
                "word": "game",
                "meaning": "게임, 오락; 경기"
            },
            {
                "word": "baseball",
                "meaning": "야구"
            },
            {
                "word": "volleyball",
                "meaning": "배구"
            },
            {
                "word": "tennis",
                "meaning": "테니스"
            },
            {
                "word": "soccer",
                "meaning": "축구"
            },
            {
                "word": "golf",
                "meaning": "골프"
            },
            {
                "word": "gym",
                "meaning": "체육관; 체육"
            },
            {
                "word": "catch",
                "meaning": "잡다; 이해하다"
            },
        ]
    },
    {
        detailId: "1022",
        list: [
            {
                "word": "heart",
                "meaning": "심장; 마음"
            },
            {
                "word": "tooth",
                "meaning": "이, 치아"
            },
            {
                "word": "skin",
                "meaning": "피부; 가죽"
            },
            {
                "word": "from",
                "meaning": "~에서, ~으로부터, ~부터"
            },
            {
                "word": "music",
                "meaning": "음악"
            },
            {
                "word": "card",
                "meaning": "카드; 두꺼운 종이"
            },
            {
                "word": "sure",
                "meaning": "확신하는; 그럼요, 물론이죠"
            },
            {
                "word": "listen",
                "meaning": "듣다"
            },
            {
                "word": "try",
                "meaning": "노력하다, 시도하다"
            },
            {
                "word": "for",
                "meaning": "~위해서, ~에 대하여; ~동안; ~행의, ~을 위하여"
            }
        ]
    },
    {
        detailId: "1023",
        list: [
            {
                "word": "but",
                "meaning": "그러나, 하지만"
            },
            {
                "word": "answer",
                "meaning": "대답; 해답"
            },
            {
                "word": "right",
                "meaning": "권리; 옳은; 맞는, 정확한; 오른쪽의, 즉시, 바로"
            },
            {
                "word": "learn",
                "meaning": "배우다"
            },
            {
                "word": "never",
                "meaning": "결코 ~하지 않는다"
            },
            {
                "word": "or",
                "meaning": "또는"
            },
            {
                "word": "and",
                "meaning": "그리고"
            },
            {
                "word": "because",
                "meaning": "~때문에, 왜냐하면"
            },
            {
                "word": "then",
                "meaning": "그때, 그 다음에, 그 후에; 그 당시의"
            },
            {
                "word": "country",
                "meaning": "국가, 나라; 시골"
            },
        ]
    },
    {
        detailId: "1024",
        list: [
            {
                "word": "explain",
                "meaning": "설명하다"
            },
            {
                "word": "pain",
                "meaning": "아픔, 통증, 고통"
            },
            {
                "word": "trouble",
                "meaning": "곤란; 병 문제, 근심; 괴롭히다"
            },
            {
                "word": "remember",
                "meaning": "기억하다"
            },
            {
                "word": "cooking",
                "meaning": "요리, 음식준비"
            },
            {
                "word": "snack",
                "meaning": "간식"
            },
            {
                "word": "go to bed",
                "meaning": "자다"
            },
            {
                "word": "go out",
                "meaning": "나가다, 외출하다"
            },
            {
                "word": "go up",
                "meaning": "회복되다, 오름세를 타다; 올라가다"
            },
            {
                "word": "wake up",
                "meaning": "깨우다, 정신을 차리다"
            },
        ]
    },
    {
        detailId: "1025",
        list: [
            {
                "word": "once",
                "meaning": "한 번; 한때; ~하면, ~하자마자"
            },
            {
                "word": "life",
                "meaning": "삶, 생명"
            },
            {
                "word": "enjoy",
                "meaning": "즐기다, 누리다"
            },
            {
                "word": "front",
                "meaning": "앞, 정면; 앞의, 정면의"
            },
            {
                "word": "change",
                "meaning": "바꾸다, 달라지다, 수정하다"
            },
            {
                "word": "live",
                "meaning": "살다"
            },
            {
                "word": "question",
                "meaning": "질문, 의문"
            },
            {
                "word": "ready",
                "meaning": "준비된; 준비하다"
            },
            {
                "word": "left",
                "meaning": "왼쪽의"
            },
            {
                "word": "age",
                "meaning": "나이, 연령"
            },
        ]
    },
    {
        detailId: "1026",
        list: [
            {
                "word": "comfortable",
                "meaning": "편안한, 안락한"
            },
            {
                "word": "fall",
                "meaning": "떨어지다. 하락하다"
            },
            {
                "word": "cute",
                "meaning": "귀여운, 예쁜"
            },
            {
                "word": "finish",
                "meaning": "끝내다, 마치다; 끝"
            },
            {
                "word": "at",
                "meaning": "~에, ~에서, ~으로"
            },
            {
                "word": "begin",
                "meaning": "시작하다, 출발하다"
            },
            {
                "word": "come out",
                "meaning": "나오다, 생산되다"
            },
            {
                "word": "come in",
                "meaning": "들어오다; 시작하다; 도착하다; 유행하다"
            },
            {
                "word": "any",
                "meaning": "무슨, 누구든, 얼마간의, 어떤"
            },
            {
                "word": "easy",
                "meaning": "쉬운; 편안한; 쉽게"
            },
        ]
    },
    {
        detailId: "1027",
        list: [
            {
                "word": "list",
                "meaning": "목록, 명단"
            },
            {
                "word": "understand",
                "meaning": "이해하다, 알아듣다"
            },
            {
                "word": "gift",
                "meaning": "선물; 재능"
            },
            {
                "word": "health",
                "meaning": "건강"
            },
            {
                "word": "pretty",
                "meaning": "예쁜, 귀여운"
            },
            {
                "word": "world",
                "meaning": "세계"
            },
            {
                "word": "year",
                "meaning": "해, 년; 나이"
            },
            {
                "word": "forget",
                "meaning": "잊다"
            },
            {
                "word": "visit",
                "meaning": "방문하다"
            },
            {
                "word": "welcome",
                "meaning": "환영하다; 반가운"
            },
        ]
    },
    {
        detailId: "1028",
        list: [
            {
                "word": "lay",
                "meaning": "눕다; 놓다; 낳다"
            },
            {
                "word": "comb",
                "meaning": "빗; 빗질하다"
            },
            {
                "word": "fruit",
                "meaning": "과일, 열매"
            },
            {
                "word": "own",
                "meaning": "자기 자신의; 스스로 하는; 고유한"
            },
            {
                "word": "nap",
                "meaning": "낮잠"
            },
            {
                "word": "cash",
                "meaning": "현금"
            },
            {
                "word": "plant",
                "meaning": "식물; 공장"
            },
            {
                "word": "lie",
                "meaning": "누워있다; 놓여있다; 거짓말"
            },
            {
                "word": "relax",
                "meaning": "휴식을 취하다"
            },
            {
                "word": "teach",
                "meaning": "가르치다"
            },
        ]
    },
    {
        detailId: "1029",
        list: [
            {
                "word": "ride",
                "meaning": "타다, 타고 가다"
            },
            {
                "word": "grow up",
                "meaning": "성장하다"
            },
            {
                "word": "bright",
                "meaning": "밝은, 빛나는; 영리한"
            },
            {
                "word": "spend",
                "meaning": "쓰다, 소비하다"
            },
            {
                "word": "wonderfull",
                "meaning": "놀랄만한, 훌륭한, 멋진, 경이로운"
            },
            {
                "word": "awake",
                "meaning": "깨우다"
            },
            {
                "word": "rest",
                "meaning": "휴식; 나머지; 쉬다, 휴식을 취하다"
            },
            {
                "word": "true",
                "meaning": "사실인, 참인, 맞는, 진짜의"
            },
            {
                "word": "message",
                "meaning": "메세지, 메모"
            },
            {
                "word": "beautiful",
                "meaning": "아름다운, 멋진"
            },
        ]
    },
    {
        detailId: "1030",
        list: [
            {
                "word": "ticket",
                "meaning": "표, 입장권"
            },
            {
                "word": "tour",
                "meaning": "여행, 관광, 순회, 견학"
            },
            {
                "word": "passport",
                "meaning": "여권"
            },
            {
                "word": "trip",
                "meaning": "여행"
            },
            {
                "word": "wood",
                "meaning": "나무, 목재"
            },
            {
                "word": "travel",
                "meaning": "여행하다, 이동하다, 여행"
            },
            {
                "word": "leaf",
                "meaning": "잎"
            },
            {
                "word": "animal",
                "meaning": "동물, 짐승"
            },
            {
                "word": "fish",
                "meaning": "생선; 낚시하다"
            },
            {
                "word": "flower",
                "meaning": "꽃"
            },
        ]
    },
    {
        detailId: "1031",
        list: [
            {
                "word": "collect",
                "meaning": "모으다, 수집하다"
            },
            {
                "word": "enough",
                "meaning": "충분한; 충분히"
            },
            {
                "word": "kindergarten",
                "meaning": "유치원"
            },
            {
                "word": "might",
                "meaning": "may의 과거, ~할지도 모른다, ~지도 모른다"
            },
            {
                "word": "free",
                "meaning": "무료의, 자유의"
            },
            {
                "word": "heavy",
                "meaning": "무거운"
            },
            {
                "word": "must",
                "meaning": "~해야 한다; ~임에 틀림없다"
            },
            {
                "word": "hospital",
                "meaning": "병원"
            },
            {
                "word": "important",
                "meaning": "중요한"
            },
            {
                "word": "dark",
                "meaning": "어두운, 캄캄한; 어둠"
            },
        ]
    },
    {
        detailId: "1032",
        list: [
            {
                "word": "draw",
                "meaning": "그리다; 끌다; 결론을 내다"
            },
            {
                "word": "store",
                "meaning": "가게, 상점"
            },
            {
                "word": "shout",
                "meaning": "외치다, 큰 소리를 내다"
            },
            {
                "word": "waste",
                "meaning": "낭비하다; 낭비; 쓰레기, 폐기물"
            },
            {
                "word": "sell",
                "meaning": "팔다"
            },
            {
                "word": "let",
                "meaning": "~하게 해주다, ~하도록 두다, 허락하다"
            },
            {
                "word": "rich",
                "meaning": "부자의, 부유한, 풍부한"
            },
            {
                "word": "lady",
                "meaning": "여성"
            },
            {
                "word": "laugh",
                "meaning": "웃다, 웃음"
            },
            {
                "word": "soft",
                "meaning": "부드러운, 푹신한"
            },
        ]
    },
    {
        detailId: "1033",
        list: [
            {
                "word": "wrong",
                "meaning": "틀린, 잘못된; 나쁜"
            },
            {
                "word": "hard",
                "meaning": "단단한; 어려운; 열심히"
            },
            {
                "word": "elementary school",
                "meaning": "초등학교"
            },
            {
                "word": "most",
                "meaning": "대부분의, 최대의, 가장 많은"
            },
            {
                "word": "half",
                "meaning": "반, 절반"
            },
            {
                "word": "dangerous",
                "meaning": "위험한"
            },
            {
                "word": "flag",
                "meaning": "깃발"
            },
            {
                "word": "could",
                "meaning": "~할 수 있었다"
            },
            {
                "word": "may",
                "meaning": "~일지도 모른다, ~해도 좋다"
            },
            {
                "word": "more",
                "meaning": "더 많은; 더"
            },
        ]
    },
    {
        detailId: "1034",
        list: [
            {
                "word": "silent",
                "meaning": "침묵의, 말을 안하는, 조용한"
            },
            {
                "word": "weather",
                "meaning": "날씨"
            },
            {
                "word": "wake",
                "meaning": "깨다, 깨우다"
            },
            {
                "word": "shadow",
                "meaning": "그림자, 어둠의, 그늘"
            },
            {
                "word": "quiet",
                "meaning": "조용한"
            },
            {
                "word": "strange",
                "meaning": "이상한, 낯선"
            },
            {
                "word": "simple",
                "meaning": "간단한, 단순한"
            },
            {
                "word": "terrible",
                "meaning": "끔찍한, 소름끼치는"
            },
            {
                "word": "will",
                "meaning": "~할 것이다"
            },
            {
                "word": "whole",
                "meaning": "전부의 모든, 온전한"
            },
        ]
    },
    {
        detailId: "1035",
        list: [
            {
                "word": "coin",
                "meaning": "동전"
            },
            {
                "word": "carry",
                "meaning": "나르다; 가지고 다니다"
            },
            {
                "word": "wind",
                "meaning": "바람"
            },
            {
                "word": "inside",
                "meaning": "~의 안쪽에; 내부의; 안쪽, 내부"
            },
            {
                "word": "tired",
                "meaning": "피곤한, 지친; 싫증난"
            },
            {
                "word": "thing",
                "meaning": "(사물을 가리키는) 것, 물건"
            },
            {
                "word": "send",
                "meaning": "보내다, 발송하다"
            },
            {
                "word": "gentleman",
                "meaning": "신사"
            },
            {
                "word": "bath",
                "meaning": "목욕, 욕조"
            },
            {
                "word": "always",
                "meaning": "항상, 언제나"
            }
        ]
    },
]

