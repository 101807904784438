import * as S from "./styles";
import {useCallback} from "react";

interface Props {
    word: string;
    translation: string;
    sendMessage: (value: string) => void;
    setPopup: (isPopup: boolean) => void;
}

const PopupWord = ({word, translation, sendMessage, setPopup}: Props) => {
    const onClickSearch = useCallback(() => {
        sendMessage(word);
        setPopup(false);
    }, []);

    return (
        <S.PopupBx>
            <S.PopupInner>
                <S.PopupContent
                    initial={{y: 20, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    exit={{y: 20, opacity: 0}}
                    transition={{
                        duration: 0.2,
                        ease: 'easeOut'
                    }}
                >
                    <S.CloseBtn>
                        <button onClick={() => setPopup(false)}>
                            <img src="/icon/ic_btn_close.svg" alt=""/>
                        </button>
                    </S.CloseBtn>
                    <S.Title>{word}</S.Title>
                    <S.SearchBx>
                        <dt>{translation}</dt>
                        <dd>
                            <button onClick={() => setPopup(false)}>닫기</button>
                            <button onClick={onClickSearch}>관련 문장 만들기</button>
                        </dd>
                    </S.SearchBx>
                </S.PopupContent>

                {/*<S.MessageBx*/}
                {/*    initial={{ opacity: 0 }}*/}
                {/*    animate={{ opacity: 1 }}*/}
                {/*    exit={{ opacity: 0 }}*/}
                {/*>*/}
                {/*    <span>'빈 곳'을 눌러 닫기</span>*/}
                {/*</S.MessageBx>*/}
            </S.PopupInner>

            <S.PopupDimmed
                onClick={() => setPopup(false)}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{
                    duration: 0.2,
                    ease: 'easeOut'
                }}
            ></S.PopupDimmed>
        </S.PopupBx>
    )
}

export default PopupWord;
