import {useParams} from "react-router-dom";
import {WordListData} from "../../../api/mock/word";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import MemorizeHeader from "../common/memorize-header";
import * as S from "./styles";
import {ListBx} from "./styles";

const WordList = () => {
    const {groupId} = useParams();
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        setData(WordListData.filter((item) => item.groupId === groupId)[0]);
    }, [groupId]);

    if (!data) return null;

    return (
        <S.Wrap>
            <MemorizeHeader/>
            <S.HeaderBx>
                <dl>
                    <dt>영어 단어</dt>
                    <dd>영어 단어를 공부해보세요.</dd>
                </dl>
            </S.HeaderBx>

            <S.Inner>
                <S.ListBx>
                    <ul>
                        {
                            data.list.map((item: any, idx: number) => (
                                <li key={`word-list-item-${idx}`}>
                                    <Link to={`/memorize/word-detail/${item.detailId}`}>
                                        {item.title}
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                </S.ListBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default WordList;