import * as S from "./styles";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {MenuBx} from "./styles";


const HeaderData = [
    {
        title: 'Home',
        represent: '/',
        link: "/"
    },
    {
        title: '문장',
        represent: '/sentence',
        link: "/sentence"
    },
    {
        title: '단어',
        represent: '/memorize',
        link: "/memorize/word"
    },
    {
        title: '정보',
        represent: '/info',
        link: "/info"
    },
]

const BottomMenu = () => {
    const location = useLocation();
    const [depthList, setDepthList] = useState<string[]>(
        location.pathname.split("/")
    );

    const [depth1, setDepth1] = useState<string>(`/${depthList[1]}`);
    const [depth2, setDepth2] = useState<string>(`${depth1}/${depthList[2]}`);

    const [isIn, setIn] = useState<boolean>(false);
    const [isShowSub, setShowSub] = useState<boolean[]>(
        Array.from({length: HeaderData.length})
    );

    useEffect(() => {
        const url = location.pathname.split("/");

        setIn(false);
        setShowSub([...isShowSub].map(() => false));

        setDepth1(`/${url[1]}`);
        setDepth2(`/${url[1]}/${url[2]}`);

        window.scrollTo(0, 0);
    }, [location]);

    return (
        <S.Wrap>
            <S.Inner>
                <S.Container>
                    <ul>
                        {
                            HeaderData.map((item, idx) => (
                                <S.MenuBx key={`header-${idx}`} $isIn={depth1 === item.represent}>
                                    <Link to={item.link}>{item.title}</Link>
                                </S.MenuBx>
                            ))
                        }
                    </ul>
                </S.Container>
            </S.Inner>
        </S.Wrap>
    )
}

export default BottomMenu;