import styled from "styled-components";

export const SwiperContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #cecece;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 50px 20px 30px;
    position: relative;
`

export const WordBx = styled.div`
    margin-bottom: 40px;
    dl{
        display: flex;
        flex-direction: column;
        align-items: center;
        dt{
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 20px;
        }
        dd{
            font-size: 14px;
            font-weight: 600;
        }
    }
`

export const Sentence = styled.div`
    position: relative;
    dl {
        display: flex;
        flex-direction: column;

        dt {
            margin-bottom: 30px;

            ul {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 5px;

                li {
                    position: relative;
                    cursor: pointer;
                    font-size: 20px;
                    font-weight: 600;
                    overflow: hidden;
                    height: 24px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                    &:hover {
                        color: blue;
                    }

                    span {
                        display: block;
                    }
                }
            }
        }

        dd {
            text-align: center;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            word-break: keep-all;
            overflow: hidden;

            span {
                display: inline-block;
            }
        }
    }
`

export const LoadingBx = styled.div`
    position: relative;
    bottom: 10px;
`

export const SentenceBtn = styled.div<{ $isLoading: boolean }>`
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 40px;
    text-align: center;

    > button {
        padding: 10px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 10px;
        background-color: ${p => p.$isLoading ? 'gray' : '#4545ee'};
        color: #ffffff;
        transition: background-color 0.3s linear;
    }
`

export const SoundBtnBx = styled.div`
    position: relative;
    top: -10px;
`